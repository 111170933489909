<template>
  <div id="app" :class="$theme">
    <router-view v-if="showView" />
    <div
      v-else
      class="d-flex align-items-center justify-content-center"
      style="height: 100vh"
    >
      <b-spinner type="grow" label="Spinning" class="text-primary"></b-spinner>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { CONFIG_REQUEST } from "./store/actions/config";
import { AUTH_LOGOUT } from "./store/actions/auth";
import { USER_REQUEST } from "./store/actions/user";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {},
  data() {
    return {
      Client: null,
    };
  },
  created: function() {
    //Se autenticato ricarico dati utente
    if (this.isAuthenticated) {
      this.$store.dispatch(USER_REQUEST);
    }

    //Richiesta di configurazioni
    this.$store.dispatch(CONFIG_REQUEST).then(() => {});

    //Se intercetta un errore 401 esegue il logout e rimanda alla pagina di login
    axios.interceptors.response.use(undefined, function(err) {
      return new Promise(function() {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch(AUTH_LOGOUT).then(() => {
            this.$router.push("/login");
          });
        }
        throw err;
      });
    });
  },

  computed: {
    ...mapGetters([
      "configuration_loaded",
      "isAuthenticated",
      "user_loaded",
      "loggedUser",
    ]),
    showView() {
      if (this.isAuthenticated) {
        return this.configuration_loaded && this.user_loaded;
      } else {
        return this.configuration_loaded;
      }
    },
  },
};
</script>

<style>
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 700px),
  only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 700px),
  only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 700px),
  only screen and (min-device-pixel-ratio: 2) and (min-width: 700px),
  only screen and (min-resolution: 192dpi) and (min-width: 700px),
  only screen and (min-resolution: 2dppx) and (min-width: 700px) {
  /* Medium screen, retina, stuff to override above media query */
  html {
    font-size: 13px;
  }
}
</style>
